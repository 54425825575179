* {
  box-sizing: border-box;
}
body {
  /* color:#555; */
  background-color: #fafafa;
  margin: 0;
  font-family: "Open Sans", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inconsolata", monospace;
  font-size: 17px;
}

pre {
  font-family: "Inconsolata", monospace;
  font-size: 17px;
}

@keyframes born-animation-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.born-animation {
  animation-name: born-animation-keyframes;
}

@keyframes death-animation-keyframes {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.death-animation {
  animation-name: death-animation-keyframes;
}
.text-area-fadeout {
  animation-name: death-animation-keyframes;
  animation-duration: 1s;
  opacity: 0;
  pointer-events: none;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}
