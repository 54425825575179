.__pyro-before,
.__pyro-after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff,
    -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e,
    226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff,
    220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff,
    155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd,
    -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff,
    69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600,
    97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff,
    140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f,
    144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff,
    -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4,
    209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00,
    139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff,
    -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff,
    244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff,
    211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff,
    189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards,
    5s position linear infinite backwards, 15s fade-in-out ease-in;
}

.__pyro-after {
  animation-delay: 1.25s, 1.25s, 1.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

.__button-hover-animation {
  cursor: pointer;
  transition: filter 0.3s;
  /* transition: box-shadow .4s*/
}

.__button-hover-animation:hover {
  filter: contrast(1.2);
  /* transform: translateX(5px) rotate(4deg); */
  /* box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;*/
}

.__button-hover-animation:active {
  filter: contrast(1.6);
  /* transform: translateX(5px) rotate(4deg); */
  /* box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;*/
}

.__shadow-2 {
  transition: box-shadow 0.5s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.__shadow-2-hover-shadow-4 {
  transition: box-shadow 0.5s, font-size 0.5s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.__shadow-2-hover-shadow-4:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.__26px-when-hover {
  font-size: 24px;
  background-color: #fff;
  border-radius: 4px;
  transition: opacity 0.5s, background-color 0.5s;
}

.__26px-when-hover:hover {
  background-color: rgba(0, 0, 0, 0.11);
}

.__26px-when-hover:active {
  background-color: rgba(0, 0, 0, 0.6);
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white,
      0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@keyframes gravity {
  to {
    transform: translateY(200px);
    opacity: 0;
  }
}

@keyframes position {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes highlight-animation-keyframes {
  0% {
    background-color: white;
  }
  50% {
    background-color: orange;
  }
  100% {
    background-color: white;
  }
}

.__highlight-animation {
  animation-name: highlight-animation-keyframes;
  animation-duration: 1s;
}

@keyframes fade-in-tooltip {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeInTooltip {
  animation: fade-in-tooltip 1s ease-out;
}
.__80-to-85-on-hover {
  font-size: 80px;
}
.__80-to-85-on-hover:hover {
  font-size: 84px;
}

.flatbutton {
  outline: none;
  cursor: pointer;
  margin: 4px;
  border-radius: 1px;
  background-color: #003366;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #eee;
  font-size: 16px;
  border: none;
  padding: 6px 8px 6px 8px;
}

.width-170 {
  width: 170px;
}

@keyframes sway-left-animation {
  0% {
    left: 0px;
  }
  50% {
    left: 20px;
  }
  100% {
    left: 0px;
  }
}

@keyframes sway-right-animation {
  0% {
    right: 0px;
  }
  50% {
    right: 20px;
  }
  100% {
    right: 0px;
  }
}

.sway-left-on-hover {
  position: relative;
  animation: sway-left-animation 2s infinite;
  animation-play-state: paused;
}

.sway-right-on-hover {
  position: relative;

  animation: sway-right-animation 2s infinite;
  animation-play-state: paused;
}

.sway-left-on-hover:hover {
  animation-play-state: running;
}

.sway-right-on-hover:hover {
  animation-play-state: running;
}

.hover-highlight:hover {
  background-color: #aaa;
}

@keyframes width-expand-250-keyframes {
  from {
    width: 0px;
  }
  to {
    width: 250px;
  }
}

.width-expand-animation-to-250 {
  animation-name: width-expand-250-keyframes;
  animation-duration: 1s;
  width: 250px;
}

@keyframes width-retract-250-keyframes {
  from {
    width: 250px;
  }
  to {
    width: 0px;
  }
}

.width-retract-animation-from-250 {
  animation-name: width-retract-250-keyframes;
  animation-duration: 1s;
  width: 0px;
}

.width-250 {
  width: 250px;
}

.youtube-iframe {
  width: 100vw;
  height: calc(100vw * 0.85);
}

@media only screen and (min-width: 600px) {
  .youtube-iframe {
    width: 560px;
    height: 315px;
  }
  .youtube-iframe-container {
    padding: 30px;
  }
}

@keyframes move-from-bottom-right {
  from {
    left: 200px;
    top: 200px;
  }
  to {
    left: 0px;
    top: 0px;
  }
}

@keyframes move-from-top-right {
  from {
    left: 200px;
    bottom: 200px;
  }
  to {
    left: 0px;
    bottom: 0px;
  }
}

@keyframes move-from-top-left {
  from {
    right: 200px;
    bottom: 200px;
  }
  to {
    right: 0px;
    bottom: 0px;
  }
}

@keyframes move-from-bottom-left {
  from {
    right: 200px;
    top: 200px;
  }
  to {
    right: 0px;
    top: 0px;
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(10deg);
  }
  25% {
    -webkit-transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(20deg);
  }
  75% {
    -webkit-transform: rotate(-5deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
